$(function() {
    console.log("hellloo world guys");
    
    $('.matchheight').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });
    $('.curved').arctext({radius: 300})
    
});


$('.open-menu').click(function () {
    $(this).toggleClass('is-active');
    if ($(".main-nav").hasClass("expanded")) {
        $(".main-nav.expanded").removeClass("expanded").slideUp(250), $(this).removeClass("is-active");
    }
    else {
        $(".main-nav").addClass("expanded").slideDown(250), $(this).addClass("is-active");
    }

});
$('.nav').click(function () {
    if ($(".main-nav").hasClass("expanded")) {
        $(".main-nav.expanded").removeClass("expanded").slideUp(250);
    }
    if ($(".js-hamburger").hasClass("is-active")) {
        $(".js-hamburger.is-active").removeClass("is-active");
    }
});
    